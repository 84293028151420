import { useRouter } from 'next/router';

import { SupportedLocales } from 'constants/i18n';
import { isClient } from 'lib/utils/generic';
import de from 'locales/de';
import en from 'locales/en';
import it from 'locales/it';
import jp from 'locales/jp';

import { useEffect } from 'react';
import { UseLocalStorage } from './useLocalStorage';

export const useTranslation = () => {
    const router = useRouter();
    const { getLanguage } = UseLocalStorage();
    const t = router?.locale === SupportedLocales.De ? de : router?.locale === SupportedLocales.Jp ? jp : router?.locale === SupportedLocales.It ? it : en;

    const setRouterLanguage = (lang: SupportedLocales, path: string) => {
        router.push(path, undefined, {
            locale: lang,
        });
    };

    useEffect(() => {
        if (isClient && router.pathname) {
            const cachedLang = getLanguage();

            if (cachedLang && (Object.values(SupportedLocales) as string[]).includes(cachedLang) && cachedLang !== router.locale) {
                setRouterLanguage(cachedLang as SupportedLocales, router.pathname);
            }
        }
    }, [isClient, router.pathname]);

    return t;
};
